<template>
	<div class="Claim-kanban">
		<div class="kanban-view-list">
			<v-flex
				v-for="(row, index) in claimData"
				:key="index"
				class="kanban-innerlist"
				style="width: calc(33.33% - 20px)"
			>
				<div :class="`${row.status.status_color} lighten-4 list-items`">
					<div class="d-flex align-center">
						<div class="fw-600 title-text text-h6">{{ row.status.text }}</div>
						<v-chip class="ml-3" outlined :color="`${row.status.status_color} white--text`">
							<span class="text-h6">
								{{ getTotalAmount(row.pricing_info) }}
							</span>
						</v-chip>
						<v-spacer></v-spacer>
					</div>
				</div>
				<Container
					v-if="false"
					group-name="leadkanbanlist"
					v-on:drop="onDrop(index, row.list, $event, row.status.value)"
					:get-child-payload="(e) => getChildPayload(row.list, index, e)"
					:drop-placeholder="dropPlaceholderOptions"
					class="overflow-list-item"
					:id="!row.list.length ? 'blank-list' : ''"
					:lock-axis="row.status.value != 1 ? 'y' : ''"
				>
					<Draggable v-model="row.status.value" v-for="(data, index) in row.list" :key="index" class="">
						<div
							:class="`${row.status.status_color} lighten-5 list-items`"
							v-on:click="pushToRoute('claims-details', { id: data.id })"
						>
							<div class="d-flex">
								<!-- <div class="ellipsis pr-6 w-80">
									<span class="fw-600 pr-2">Claim # : </span>
									<span class="fw-500" v-if="data && data.barcode">{{ data.barcode }}</span>
									<span v-else>no barcode</span>
								</div> -->
								<!-- <div class="ellipsis fw-500 text-end w-20">
									<template v-if="data && data.category">
										<span class="fw-500">
											<v-chip
												class="ml-2 text-capitalize"
												:color="getcategoryColor(data.category)"
												x-small
												label
												v-if="data && data.category"
											>
												<template v-if="data && data.category == 'owan'"> Own </template>
												<template v-else>
													{{ data.category.replace("-", " ") }}
												</template>
											</v-chip></span
										>
									</template>
								</div> -->
							</div>
							<div class="ellipsis pr-6 mb-1">
								<span class="fw-600 pr-2">Title : </span>
								<span class="fw-500" v-if="data && data.billable_name">{{ data.billable_name }}</span>
								<em class="text-muted" v-else>no title</em>
							</div>
							<div class="ellipsis pr-6">
								<span class="fw-600 pr-2">User : </span>
								<span class="fw-500 primary--text" v-if="data && data.user_name">
									<v-chip small>
										<v-avatar left>
											<img v-if="data.profile_img" :src="data.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-avatar>

										{{ data.user_name }}
									</v-chip>
									<!-- {{ data.user_name }} -->
								</span>
								<em class="text-muted" v-else>no user name</em>
							</div>
							<div class="ellipsis pr-6" v-if="data && data.category == 'customer'">
								<span class="fw-600 pr-2">Company : </span>
								<span class="fw-500 primary--text" v-if="data && data.customer_company_name">{{
									data.customer_company_name
								}}</span>
								<em class="text-muted" v-else>no company</em>
							</div>
							<!-- <div class="ellipsis pr-6 mb-1">
								<span class="fw-600 pr-2">Shop Name : </span>
								<span class="fw-500" v-if="data && data.shop_name">{{ data.shop_name }}</span>
								<em class="text-muted" v-else>no shop name</em>
							</div> -->
							<div class="d-flex pr-6 my-1">
								<div>
									<span class="fw-600 pr-2">Amount: </span>
									<v-chip x-small label color="green" outlined v-if="data && data.amount">
										<span class="fw-500" v-if="data && data.amount">{{ formatMoney(data.amount) }}</span>
									</v-chip>
									<span v-else>
										<v-chip x-small label color="green" outlined>
											<span class="fw-500">$0.00</span>
										</v-chip>
									</span>
								</div>

								<!-- <v-spacer></v-spacer>
								<div>
									<span class="fw-500 primary--text" v-if="data && data.expense_date">{{
										formatDate(data.expense_date)
									}}</span>
									<span v-else>no expense date</span>
								</div> -->
							</div>
							<div class="ellipsis pr-6 mb-1">
								<span class="fw-600 pr-2">Category: </span>
								<v-chip
									text-color=""
									outlined
									label
									color="blue white--text"
									x-small
									v-if="data && data.expense_category"
								>
									<span class="fw-500" v-if="data && data.expense_category">{{
										data.expense_category
									}}</span>
								</v-chip>
								<em class="text-muted" v-else>no expense category</em>
							</div>
							<!-- <v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<div class="pdf-download" v-on="on" v-bind="attrs">
										<v-icon link color="red">mdi-file-pdf-outline</v-icon>
									</div>
								</template>
								<span>Download</span>
							</v-tooltip> -->
						</div>
					</Draggable>
				</Container>

				<ClaimKanbanList
					v-if="row"
					:key="new Date().getTime()"
					v-on:drop="onDrop"
					v-on:child-payload="getChildPayload"
					:index-value="index"
					:lead-details="row"
					pagination-end-point="claim-kanban-view"
				/>
				<!-- v-on:open-reminder="openReminder"  
					@update:lead="update_lead($event)"
					 v-on:open-remark="openRemark" -->
			</v-flex>

			<template v-if="false">
				<Dialog :dialog="statusDialog" :dialog-width="900">
					<template v-slot:title
						>Status Remark
						<v-chip
							class="ml-2 mr-2 text-capitalize"
							:color="getStatusColor(status_remark.drop_status)"
							small
							label
							v-if="status_remark && status_remark.drop_status"
							>{{ status_remark.drop_status }}</v-chip
						>
						<v-chip
							class="ml-2 mr-2 text-capitalize"
							outlined
							color="red white--text"
							small
							label
							v-if="claimSingle && claimSingle.category"
							>{{ claimSingle.category.replace("-", " ") }}</v-chip
						>
						<v-chip class="mb-1 mr-1" small>
							<v-avatar left small>
								<img v-if="claimSingle.profile_img" :src="claimSingle.profile_img" />
								<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
							</v-avatar>
							<span v-if="claimSingle && claimSingle.user_name">{{ claimSingle.user_name }}</span>
						</v-chip>
					</template>
					<template v-slot:body>
						<v-form
							ref="extendCheckoutForm"
							v-model.trim="formValid"
							lazy-validation
							v-on:submit.stop.prevent="onSubmit"
						>
							<v-row class="mb-3">
								<v-col lg="3" cols="12" class="my-auto py-0">
									<Label for="" class="fw-500 primary--text"> Summary :</Label>
								</v-col>
								<v-col lg="9" class="my-auto py-0">
									<span
										class="fw-600 font-size-18"
										v-if="summaryData && summaryData[0] && summaryData[0].category"
										>{{ summaryData[0].category }}</span
									>
									<span>(Category)</span>
								</v-col>
								<!--<v-col lg="2" class="my-auto py-0"><label class="input-label">Claimed</label></v-col>
								<v-col lg="2" class="my-auto py-0"><label class="input-label">Balance</label></v-col>
								<v-col lg="4" class="my-auto py-0"><label class="input-label">Category</label></v-col> -->
							</v-row>
							<v-row class="mb-3">
								<v-col lg="3" cols="12" class="my-auto py-0">
									<label class="input-label"></label>
								</v-col>
								<v-col lg="2" class="my-auto py-0">
									<div for="" class="fw-500 mb-2">Total</div>
									<v-chip color="green" label outlined small
										><span v-if="summaryData && summaryData[0] && summaryData[0].allocated_amount">{{
											formatMoney(summaryData[0].allocated_amount)
										}}</span>
										<span v-else> $ 0.00</span></v-chip
									></v-col
								>
								<v-col lg="2" class="my-auto py-0">
									<div for="" class="fw-500 mb-2">Claimed</div>
									<v-chip color="orange" label outlined small
										><span v-if="summaryData && summaryData[0] && summaryData[0].taken">{{
											formatMoney(summaryData[0].taken)
										}}</span>
										<span v-else> $ 0.00</span></v-chip
									></v-col
								>
								<v-col lg="2" class="my-auto py-0">
									<div for="" class="fw-500 mb-2">Balance</div>
									<v-chip color="red" label outlined small>
										<span v-if="summaryData && summaryData[0] && summaryData[0].balance">
											{{ formatMoney(summaryData[0].balance) }}</span
										><span v-else> $ 0.00</span></v-chip
									></v-col
								>
								<!-- <v-col lg="4" class="my-auto py-0"
									><v-chip color="blue" label outlined small
										><span v-if="summaryData && summaryData[0] && summaryData[0].category">{{
											summaryData[0].category
										}}</span></v-chip
									></v-col
								> -->
							</v-row>
							<!-- <v-row class="mb-3">
								<v-col lg="2" cols="12" class="my-auto py-0">
									<Label for="" class="fw-500"> Status :</Label>
								</v-col>
								<v-col md="10" class="py-0">
									<span class="primary--text">{{ status_remark.drop_status }}</span>
								</v-col>
							</v-row> -->
							<v-row class="mb-3">
								<v-col lg="3" cols="12" class="">
									<Label for="" class="fw-500 d-flex"> Requested Amount :</Label>
								</v-col>
								<v-col md="9" class="py-0">
									<span class="green--text">{{ formatMoney(requestedAmount) }}</span>
								</v-col>
							</v-row>
							<v-row class="mb-3">
								<v-col lg="3" cols="12" class="">
									<Label for="" class="fw-500 d-flex"> Approved Amount :</Label>
								</v-col>
								<v-col md="9" class="py-0">
									<v-text-field
										hide-details
										type="number"
										outlined
										placeholder="Drop Status"
										v-model="status_remark.amount"
									>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row class="mb-3">
								<v-col lg="3" cols="12" class="">
									<Label for="" class="fw-500 d-flex"> Remark :</Label>
								</v-col>
								<v-col md="9" class="py-0">
									<TextAreaInput
										:rows="4"
										dense
										:word-limit="200"
										hide-details
										placeholder="Enter remark..."
										v-model="status_remark.remark"
									></TextAreaInput>
								</v-col>
							</v-row>
						</v-form>
					</template>
					<template v-slot:action>
						<v-row class="delete-dialog">
							<v-col md="12" class="py-0 my-auto">
								<p class="btx-p m-0 text-align-center" style="text-align: center">
									<span
										class="red--text lighten-1 font-level-1-bold"
										style="font-size: 19px !important; color: red !important"
										>Are you sure, You want to
										<span style="color: #24326d"
											><template v-if="status_remark.drop_status == 'Approved'">Approve</template
											><template v-if="status_remark.drop_status == 'Rejeacted'">Rejeact</template></span
										>
										this Claim
										<!-- {{ leaveBarcode }} --></span
									>
								</p>
							</v-col>
						</v-row>

						<v-btn
							class="white--text"
							:loading="pageLoading"
							:disabled="!formValid || pageLoading"
							depressed
							color="blue darken-4"
							tile
							v-on:click="onSubmit()"
						>
							Save
						</v-btn>
						<v-btn depressed tile :disabled="pageLoading" v-on:click="closeDialog"> Close </v-btn>
					</template>
				</Dialog>
			</template>
		</div>
		<ClaimApprovalDialog
			v-if="statusDialog"
			:dialog="statusDialog"
			:action-type="status_remark.drop_value"
			:claim-id="claimSingle.id"
			:claim-data="claimSingle"
			:persistent="true"
			@close="statusDialog = false"
			@success="getClaimListing(), callAllListing()"
		/>
	</div>
</template>
<style>
.v-application .grey.lighten-4 {
	background-color: #dfdfdf !important;
	border-color: #f5f5f5 !important;
}
</style>
<script>
//import ProjectMember from "@/view/module/project/ProjectMember";
/* import { getCurrentUser } from "@/core/services/jwt.service"; */
import { Container, Draggable } from "vue-smooth-dnd";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import ClaimApprovalDialog from "@/view/module/claims/ClaimApprovalDialog.vue";
import { QUERY, PATCH, POST, GET } from "@/core/services/store/request.module";
import { filter, cloneDeep, toSafeInteger, reduce } from "lodash";
import ClaimKanbanList from "@/view/components/ClaimKanbanList";
export default {
	name: "ClaimKanban",
	components: {
		Container,
		Draggable,
		Dialog,
		TextAreaInput,
		ClaimApprovalDialog,
		ClaimKanbanList,
		//ProjectMember,
	},
	props: {
		customStatus: {
			type: String,
			default() {
				return null;
			},
		},
	},
	data() {
		return {
			dropPlaceholderOptions: {
				className: "lead-drop-preview",
				animationDuration: "50",
				showOnTop: true,
			},
			manageNote: false,
			approvalActionType: null,
			claimId: 0,
			claimSingle: {},
			statusdata: null,
			pageLoading: false,
			dragstatus: null,
			expenseList: [],
			summaryData: [],
			formValid: true,
			statusDialog: false,
			requestedAmount: 0,
			status_remark: {
				drop_status: null,
				remark: null,
				drop_value: 0,
				amount: 0,
			},
			amount_status_wise: {
				pending_amount: 0,
				approved_amount: 0,
				rejected_amount: 0,
			},
			claimData: [],
		};
	},

	watch: {
		$route: {
			handler() {
				const { query } = this.$route;
				if (query) {
					query.customStatus = this.customStatus;
				}
				this.getClaimListing(query);
			},
		},
	},
	methods: {
		callAllListing() {
			//console.log('test');
			this.$emit("scucess");
		},
		getTotalAmount(amount = 0) {
			return this.formatMoney(amount);
		},
		closeDialog() {
			this.statusDialog = false;
		},
		openStatusDialog(value) {
			this.getCategoryOptions();

			//	console.log(value)
			this.requestedAmount = this.status_remark.amount;
			if (value == 2) {
				(this.status_remark.drop_status = "Approved"), (this.status_remark.drop_value = 2);
			} else if (value == 3) {
				(this.status_remark.drop_status = "Rejeacted"), (this.status_remark.drop_value = 3);
			} /* else if (value == 3) {
				(this.status_remark.drop_status = "Overdue"), (this.status_remark.drop_value = 3);
			} else if (value == 4) {
				(this.status_remark.drop_status = "Upcoming"), (this.status_remark.drop_value = 4);
			} */
			this.statusDialog = true;
		},

		openRemark(data) {
			console.log(data);
			// this.leadBarcode = data.barcode;
			// this.default_remakr = data.remark;
			// this.default_date = toString(MomentJS(data.added_at));
			// this.lead_id = data.id;
			// /* this.getRemark(data.id); */

			// this.remarkDialog = true;

			//new written
			this.getCategoryOptions();
			this.requestedAmount = this.status_remark.amount;
			// if (value == 2) {
			// 	(this.status_remark.drop_status = "Approved"), (this.status_remark.drop_value = 2);
			// } else if (value == 3) {
			// 	(this.status_remark.drop_status = "Rejeacted"), (this.status_remark.drop_value = 3);
			// }
			this.statusDialog = true;
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.extendCheckoutForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.extendCheckoutForm.validate()) {
				return false;
			}

			this.pageLoading = true;
			this.$store
				.dispatch(POST, { url: `status-remark-claim/${this.claimId}`, data: this.status_remark })
				.then(({ data }) => {
					this.responseData = data;
					this.statusDialog = false;
					this.getClaimListing();
					this.$store.commit(SET_MESSAGE, [{ model: true, message: "Succes updated successfully." }]);
					(this.status_remark = {}), this.$refs.form.reset();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCategoryOptions() {
			const _this = this;
			_this.pageLoading = true;
			_this.$store
				.dispatch(GET, {
					url: "setting/claim/option/" + this.claimSingle.user,
				})
				.then((data) => {
					_this.expenseList = data.claim_categories;
					this.summaryData = filter(_this.expenseList, function (row) {
						return row.category == _this.claimSingle.expense_category;
					});
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});

			/* 	this.categoryDays(); */
		},
		totalAmount(data) {
			//console.log(data);
			let result = reduce(
				data,
				(acc, row) => {
					acc = toSafeInteger(acc) + toSafeInteger(row && row.amount ? row.amount : 0);
					return this.formatMoney(acc);
				},
				0
			);
			return result;
			/* also use this 
			var fAmount = 0; 	
			for(var i=0; i < data.length; i++){
				 fAmount += data[i].amount;
			}
			return fAmount;  
			*/
		},
		getClaimListing(query) {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `claim-kanban-view`,
					data: {
						status: "all",
						...query,
					},
				})
				.then((data) => {
					this.claimData = data;
					this.customStatus = null;
					// const output = [];
					// for (let i = 0; i < data.status.length; i++) {
					// 	if (data.status[i].value == "all") {
					// 		continue;
					// 	}

					// 	output.push({
					// 		status: data.status[i],
					// 		list: filter(data.tbody, { status: data.status[i].text }),
					// 	});
					// }

					// for (let j = 0; j < data.pricing_counting.length; j++) {
					// 	//console.log(data.pricing_counting[j].text);
					// 	if (data.pricing_counting[j].text == "Pending") {
					// 		this.amount_status_wise.pending_amount = data.pricing_counting[j].pending_amount;
					// 	}
					// 	if (data.pricing_counting[j].text == "Approved") {
					// 		this.amount_status_wise.approved_amount = data.pricing_counting[j].approved_amount;
					// 	}
					// 	if (data.pricing_counting[j].text == "Rejected") {
					// 		this.amount_status_wise.rejected_amount = data.pricing_counting[j].rejected_amount;
					// 	}
					// }
					//console.log(this.amount_status_wise);

					// this.claimData = output;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		onDrop(values) {
			const { index, collection, dropResult, dropStatus } = values;
			/* if (this.is_staff) {
				return false;
			} */

			const { addedIndex } = dropResult;

			if (addedIndex != null) {
				this.statusdata = dropStatus;
			}
			//console.log(this.dragstatus);
			if (this.dragstatus == "Approved" || this.dragstatus == "Rejected") {
				this.claimData[index].list = this.applyDrag(collection, dropResult);
				this.getClaimListing();
				return false;
			}
			if (this.statusdata == 2 || this.statusdata == 3) {
				// console.log(this.claimData[index].list,'data');
				this.claimData[index].list = this.applyDrag(collection, dropResult);
				this.getClaimListing();
				this.openStatusDialog(this.statusdata, collection);
				return false;
			}

			if (addedIndex != null) {
				this.update_loading = true;
				this.$store
					.dispatch(PATCH, { url: `claim/${this.claimId}/${this.statusdata}` })
					.then(() => {
						this.getClaimListing();
					})
					.catch((error) => {
						this.logError(error);
					})
					.finally(() => {
						this.update_loading = false;
					});
			}

			this.claimData[index].list = this.applyDrag(collection, dropResult);
		},
		getChildPayload(values) {
			const { row, index, event } = values;

			this.claimId = row[event].id;
			this.claimSingle = row[event];

			if (this.claimSingle && this.claimSingle.amount) {
				this.status_remark.amount = Number(this.claimSingle.amount).toFixed(2);
			}
			this.dragstatus = row[event].status;
			return this.claimData[index];
		},

		applyDrag(output, dragResult) {
			const { removedIndex, addedIndex, payload } = dragResult;
			if (removedIndex === null && addedIndex === null) return output;
			const result = cloneDeep(output);
			let itemToAdd = payload.list[addedIndex];
			if (removedIndex !== null) {
				itemToAdd = result.splice(removedIndex, 1)[0];
			}
			if (addedIndex !== null) {
				result.splice(addedIndex, 0, itemToAdd);
			}
			return result;
		},
		getStatusColor(status) {
			if (status == "Approved") {
				return "green  white--text";
			} else {
				return "red white--text";
			}
		},
		getcategoryColor(cate) {
			if (cate == "owan") {
				return "red  white--text";
			} else {
				return "blue white--text";
			}
		},
	},
	computed: {
		/* is_staff() {
			const user = getCurrentUser();
			const role_name = user.role;
			return role_name == "Staff";
		}, */
	},
	mounted() {
		this.getClaimListing();
	},
};
</script>
